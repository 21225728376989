import React, { Fragment, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faAt, faPhone } from "@fortawesome/free-solid-svg-icons";

import logo from "../../../Images/logo-light.png";
import { LanguageContext } from "../../../Contexts/LanguageContext";

const FooterComponent = () => {
   const { language } = useContext(LanguageContext);

   const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
         section.scrollIntoView({
            behavior: "smooth",
         });
      }
   };

   const sections = {
      en: [
         <span style={{ cursor: "pointer" }} className="text-reset text-decoration-none d-block" onClick={() => scrollToSection("about-me")}>
            About Me
         </span>,
         <span style={{ cursor: "pointer" }} className="text-reset text-decoration-none d-block" onClick={() => scrollToSection("skills")}>
            Skills
         </span>,
         <span style={{ cursor: "pointer" }} className="text-reset text-decoration-none d-block" onClick={() => scrollToSection("projects")}>
            Projects
         </span>,
         <span style={{ cursor: "pointer" }} className="text-reset text-decoration-none d-block" onClick={() => scrollToSection("services")}>
            Services
         </span>,
         <span style={{ cursor: "pointer" }} className="text-reset text-decoration-none d-block" onClick={() => scrollToSection("contact")}>
            Contact
         </span>,
      ],
      es: [
         <span style={{ cursor: "pointer" }} className="text-reset text-decoration-none d-block" onClick={() => scrollToSection("about-me")}>
            Sobre Mi
         </span>,
         <span style={{ cursor: "pointer" }} className="text-reset text-decoration-none d-block" onClick={() => scrollToSection("skills")}>
            Habilidades
         </span>,
         <span style={{ cursor: "pointer" }} className="text-reset text-decoration-none d-block" onClick={() => scrollToSection("projects")}>
            Proyectos
         </span>,
         <span style={{ cursor: "pointer" }} className="text-reset text-decoration-none d-block" onClick={() => scrollToSection("services")}>
            Servicios
         </span>,
         <span style={{ cursor: "pointer" }} className="text-reset text-decoration-none d-block" onClick={() => scrollToSection("contact")}>
            Contacto
         </span>,
      ],
   };

   return (
      <div className="estol-bg-danger mt-auto text-light text-center">
         <div className="container-fluid">
            <div className="row g-3 mb-5 mt-5 d-flex flex-fill">
               <div className="col-lg-3 d-flex">
                  <div className="py-3 m-auto">
                     <h3>{language === "en" ? "Social" : "Redes Sociales"}</h3>
                     <div className="d-flex">
                        <a href="https://www.linkedin.com/in/estol-dev/" target="_blank" rel="noreferrer" className="text-reset fs-1 mx-3">
                           <FontAwesomeIcon icon={faLinkedinIn} />
                        </a>
                        <a href="https://github.com/estol-web-design" target="_blank" rel="noreferrer" className="text-reset fs-1 mx-3">
                           <FontAwesomeIcon icon={faGithub} />
                        </a>
                        <a href="https://www.instagram.com/eshteban_/" target="_blank" rel="noreferrer" className="text-reset fs-1 mx-3">
                           <FontAwesomeIcon icon={faInstagram} />
                        </a>
                     </div>
                  </div>
               </div>
               <div className="col-lg-3 fs-5 d-flex">
                  <div className="py-3 m-auto">
                     {sections[language].map((section, i) => (
                        <Fragment key={i}>{section}</Fragment>
                     ))}
                  </div>
               </div>
               <div className="col-lg-3 d-flex">
                  <div className="py-3 m-auto">
                     <h3>{language === "en" ? "Contact Me" : "Contáctame"}</h3>
                     <div className="d-flex justify-content-center mb-2">
                        <FontAwesomeIcon icon={faPhone} className="me-2 fs-4" />
                        <a href="tel:+59898223194" className="text-reset text-decoration-none my-auto">
                           (+598) 98 223 194
                        </a>
                     </div>
                     <div className="d-flex justify-content-center">
                        <FontAwesomeIcon icon={faAt} className="me-2 fs-4" />
                        <a href="mailto:contact@estol.dev" className="text-reset text-decoration-none my-auto">
                           contact@estol.dev
                        </a>
                     </div>
                  </div>
               </div>
               <div className="col-lg-3 d-flex">
                  <div className="m-auto w-50 py-3">
                     <img src={logo} alt="ESTOL" className="img-fluid mb-3" />
                     <span className="">&copy; {new Date().getFullYear()}</span>
                  </div>
               </div>
            </div>
            <small>Handcrafted by me</small>
         </div>
      </div>
   );
};

export default FooterComponent;
