export const axiosConfig = (method, url, data, credentials) => {
   const config = {
      method,
      url,
   };

   if (data) {
      config.data = data;
   }

   if (credentials) {
      config.withCredentials = true;
   }

   return config;
};
