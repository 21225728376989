import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../../Contexts/LanguageContext";

// images
import apis from "../../../Images/apis.jpg";
import blogs from "../../../Images/blogs.jpg";
import corporate from "../../../Images/corporate.jpg";
import ecommerce from "../../../Images/ecommerce.jpg";
import educational from "../../../Images/educational.jpg";
import portfolio from "../../../Images/portfolio.jpg";
import realTimeApps from "../../../Images/real-time-apps.jpg";
import social from "../../../Images/social.jpg";

const ServicesComponent = () => {
   const { language } = useContext(LanguageContext);
   const [services, setServices] = useState([]);

   const title = {
      en: "My Services",
      es: "Mis Servicios",
   };

   useEffect(() => {
      if (language === "en") {
         setServices({
            Ecommerce: {
               description:
                  "I provide full-stack development solutions specialized in creating e-commerce platforms. From implementing secure payment systems to optimizing user experience, my comprehensive approach ensures the construction of effective and visually appealing online stores that enhance your digital presence and increase conversions.",
               image: ecommerce,
            },
            "Online-Portfolio": {
               description:
                  "I transform your ideas and achievements into a unique digital experience. With skills in both front-end and back-end development, intuitive design, and meticulous attention to detail, I build online portfolios that showcase your skills and projects attractively. Each page is designed to impress potential clients and employers, offering a professional presentation of your work.",
               image: portfolio,
            },
            "Social-Media-Platforms": {
               description:
                  "I develop custom social media platforms that go beyond standard frameworks. From integrating specific features to creating an engaging and user-friendly design, my full-stack development approach enables the creation of social media platforms tailored to your unique needs. I facilitate user connection and engagement to strengthen the community around your brand.",
               image: social,
            },
            "Corporate-Website": {
               description:
                  "I offer comprehensive solutions for the design and development of corporate websites. From strategic planning to technical implementation, I create websites that reflect your company's identity and enhance the user experience. Whether you need an informational platform, a customer portal, or a business application, I am ready to drive the online growth of your business.",
               image: corporate,
            },
            "RealTime-Web-Applications": {
               description:
                  "Development of real-time web applications using Node.js and WebSockets. I provide solutions that enable instant updates and real-time collaboration, ideal for online chats, notification systems, and applications requiring immediate data synchronization.",
               image: realTimeApps,
            },
            "API-Integration-and-External-Services": {
               description:
                  "Specialization in integrating APIs and external services to enhance application capabilities. I offer services to integrate payment systems, geolocation services, third-party authentication, and other external services, thereby improving the functionality and efficiency of applications.",
               image: apis,
            },
            "Development-of-Online-Educational-Platforms": {
               description:
                  "Development of online educational platforms with advanced features, such as Learning Management Systems (LMS), video conferencing integration, student progress tracking, and customization of educational content. I offer comprehensive solutions for educational institutions and companies looking to deliver high-quality online learning experiences.",
               image: educational,
            },
            "Blog-Development": {
               description:
                  "I offer specialized services in tailor-made blog creation using technologies such as React and Node.js. I design intuitive and visually appealing blogs that showcase your content. From implementing comment functionalities to optimizing for search engines (SEO), I ensure the creation of blogs that not only captivate readers but also meet the specific needs of your brand.",
               image: blogs,
            },
         });
      } else if (language === "es") {
         setServices({
            Ecommerce: {
               description:
                  "Ofrezco soluciones de desarrollo full stack especializadas en la creación de plataformas de comercio electrónico. Desde la implementación de sistemas de pago seguros hasta la optimización de la experiencia del usuario, mi enfoque integral garantiza la construcción de tiendas en línea efectivas y atractivas que potenciarán tu presencia digital y aumentarán las conversiones.",
               image: ecommerce,
            },
            Portafolios: {
               description:
                  "Transformo tus ideas y logros en una experiencia digital única. Con habilidades en desarrollo front-end y back-end, diseño intuitivo y atención al detalle, construyo portafolios en línea que destacan tus habilidades y proyectos de manera atractiva. Cada página está diseñada para impresionar a clientes potenciales y empleadores, brindando una presentación profesional de tu trabajo.",
               image: portfolio,
            },

            "Redes-Sociales": {
               description:
                  "Desarrollo redes sociales personalizadas que van más allá de las plataformas estándar. Desde la integración de funciones específicas hasta un diseño atractivo y fácil de usar, mi enfoque en el desarrollo full stack permite la creación de redes sociales que se adaptan a tus necesidades únicas. Facilito la conexión y la participación de usuarios para fortalecer la comunidad en torno a tu marca.",
               image: social,
            },
            "Sitios-Empresariales": {
               description:
                  "Ofrezco soluciones integrales para el diseño y desarrollo de sitios web empresariales. Desde la planificación estratégica hasta la implementación técnica, creo sitios web que reflejan la identidad de tu empresa y mejoran la experiencia del usuario. Ya sea que necesites una plataforma informativa, un portal de clientes o una aplicación empresarial, estoy preparado para impulsar el crecimiento de tu negocio en línea.",
               image: corporate,
            },
            "Aplicaciones-Web-de-Tiempo-Real": {
               description:
                  "Desarrollo de aplicaciones web en tiempo real utilizando Node.js y WebSockets. Ofrezco soluciones que permiten actualizaciones instantáneas y colaboración en tiempo real, ideales para chats en línea, sistemas de notificaciones y aplicaciones que requieren sincronización inmediata de datos.",
               image: realTimeApps,
            },
            "Integración-de-APIs-y-Servicios-Externos": {
               description:
                  "Especialización en la integración de APIs y servicios externos para expandir las capacidades de las aplicaciones. Ofrezco servicios para integrar sistemas de pago, servicios de geolocalización, autenticación de terceros y otros servicios externos, mejorando así la funcionalidad y eficiencia de las aplicaciones.",
               image: apis,
            },
            "Desarrollo-de-Plataformas-Educativas-en-Línea": {
               description:
                  "Creación de plataformas educativas en línea con funcionalidades avanzadas, como sistemas de gestión de aprendizaje (LMS), integración de videoconferencias, seguimiento del progreso del estudiante y personalización del contenido educativo. Ofrezco soluciones completas para instituciones educativas y empresas que buscan ofrecer experiencias de aprendizaje en línea de alta calidad.",
               image: educational,
            },
            "Desarrollo-de-Blogs": {
               description:
                  "Ofrezco servicios especializados en la creación de blogs a medida, utilizando tecnologías como React y Node.js. Diseño blogs intuitivos y visualmente atractivos que destacan tu contenido. Desde la implementación de funcionalidades de comentarios hasta la optimización para motores de búsqueda (SEO), garantizo la creación de blogs que no solo cautivan a los lectores, sino que también satisfacen las necesidades específicas de tu marca.",
               image: blogs,
            },
         });
      }
   }, [language]);

   return (
      <section id="services" className="container-fluid py-5">
         <h2 className="text-center mb-3 pt-5">{title[language]}</h2>
         <div className="px-3">
            <div className="row g-5 d-flex flex-fill justify-content-center">
               {Object.entries(services).map(([name, service]) => (
                  <article key={name} className="col-lg-3 col-md-5 col-sm-8 col-10">
                     <div className="border rounded estol-shadow h-100 d-flex flex-column bg-light">
                        <img src={service.image} alt={name} className="rounded-top" />
                        <div className="p-3 text-center my-auto">
                           <h3 className="estol-text-danger">{name.replaceAll("-", " ")}</h3>
                           <p>{service.description}</p>
                        </div>
                     </div>
                  </article>
               ))}
            </div>
         </div>
      </section>
   );
};

export default ServicesComponent;
