import React, { useContext } from "react";
import logo from "../../../Images/logo.png";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../../Contexts/LanguageContext";

const HeaderComponent = () => {
   const { language, toggleLanguage } = useContext(LanguageContext);

   const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
         section.scrollIntoView({
            behavior: "smooth",
         });
      }
   };

   const routes = {
      en: {
         about: (
            <span className="nav-link" onClick={() => scrollToSection("about-me")}>
               About Me
            </span>
         ),
         skills: (
            <span className="nav-link" onClick={() => scrollToSection("skills")}>
               Skills
            </span>
         ),
         projects: (
            <span className="nav-link" onClick={() => scrollToSection("projects")}>
               Projects
            </span>
         ),
         services: (
            <span className="nav-link" onClick={() => scrollToSection("services")}>
               Services
            </span>
         ),
         contact: (
            <span className="nav-link" onClick={() => scrollToSection("contact")}>
               Contact
            </span>
         ),
         resume: (
            <a className="btn estol-btn-danger ms-3 rounded-pill estol-shadow" href={"/cv.pdf"}>
               My resume
            </a>
         ),
      },
      es: {
         about: (
            <span className="nav-link" onClick={() => scrollToSection("about-me")}>
               Sobre Mi
            </span>
         ),
         skills: (
            <span className="nav-link" onClick={() => scrollToSection("skills")}>
               Habilidades
            </span>
         ),
         projects: (
            <span className="nav-link" onClick={() => scrollToSection("projects")}>
               Proyectos
            </span>
         ),
         services: (
            <span className="nav-link" onClick={() => scrollToSection("services")}>
               Servicios
            </span>
         ),
         contact: (
            <span className="nav-link" onClick={() => scrollToSection("contact")}>
               Contact
            </span>
         ),
         resume: (
            <a className="btn estol-btn-danger ms-3 rounded-pill estol-shadow" href={"/cv.pdf"}>
               Mi curriculum
            </a>
         ),
      },
   };
   return (
      <header>
         <nav className="navbar navbar-expand-lg border-bottom">
            <div className="container-fluid">
               <Link className="navbar-brand" to="/">
                  <img src={logo} alt="estol.dev" />
               </Link>
               <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
               </button>
               <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav ms-auto text-center align-items-center">
                     {Object.entries(routes[language]).map(([key, navLink]) => (
                        <li key={key} className="nav-item" style={{ cursor: "pointer" }}>
                           {navLink}
                        </li>
                     ))}
                     <li className="nav-item d-flex ms-lg-3 py-2">
                        <span className="fs-4 my-auto me-4 d-lg-block d-none">|</span>
                        <span className="my-auto me-2">{language === "en" ? "Lang:" : "Idioma:"}</span>
                        <div id="lang-selector" className="d-flex p-2 border rounded-pill" onClick={toggleLanguage} style={{ cursor: "pointer" }}>
                           <span className={language === "en" ? "active rounded-pill text-light fw-bold me-1 px-1" : "px-1"}>EN</span>
                           <span className={language === "es" ? "active rounded-pill text-light fw-bold ms-1 px-1" : "px-1"}>ES</span>
                        </div>
                     </li>
                  </ul>
               </div>
            </div>
         </nav>
      </header>
   );
};

export default HeaderComponent;
