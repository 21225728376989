import React, { useContext } from "react";
import { LanguageContext } from "../../../Contexts/LanguageContext";
import logoJR from "../../../Images/logo-jr.png";
import logoTiznado from "../../../Images/tiznado_logo.png";
import logoMoovieGram from "../../../Images/logo-mooviegram.png";
import GridComponent from "./ProjectsComponent/GridComponent";

const ProjectsComponent = () => {
    const { language } = useContext(LanguageContext);

    const title = {
        en: "My Projects",
        es: "Mis Proyectos",
    };

    const subtitles = {
        en: {
            subtitle1: "Completed",
            subtitle2: "Developing",
        },
        es: {
            subtitle1: "Completados",
            subtitle2: "En desarrollo",
        },
    };

    const projects = {
        en: {
            "JR-Propiedades": {
                logo: logoJR,
                description:
                    "A web application designed for a prominent real estate company, specializing in temporary rentals of properties located in some of the most picturesque resorts in the department of Rocha, Uruguay. This platform provides its clients with the ability to efficiently explore available properties during their chosen dates, offering them the opportunity to reserve the property that best suits their preferences and needs.",
                link: "https://estol-jr-propiedades-77jj4.ondigitalocean.app/",
                status: "completed",
            },
            Tiznado: {
                logo: logoTiznado,
                description:
                    "Portfolio for a wood sculptor from Chuy, Rocha, Uruguay. Highlighting the reuse and recycling of materials, as well as the use of natural pigmentation, this portfolio showcases the artist's sculptural creations to the world.",
                link: "https://tiznado.uy",
                status: "completed",
            },
            MoovieGram: {
                logo: logoMoovieGram,
                description:
                    "MooviGram is a personal project. It stands as a social network designed for the purpose of sharing and reviewing movies. Users have the ability to post their cinematic experiences along with insightful opinions, enabling their followers to discover and engage with these recommendations. Within their user profiles, individuals can seamlessly share the films they have already enjoyed and curate a list of movies they aspire to watch in the future.",
                link: "https://mooviegram.com",
                status: "developing",
            },
        },
        es: {
            "JR-Propiedades": {
                logo: logoJR,
                description:
                    "Una aplicación web diseñada para una destacada empresa inmobiliaria, especializada en alquileres temporales de propiedades ubicadas en algunos de los más pintorescos balnearios del departamento de Rocha, Uruguay. Esta plataforma proporciona a sus clientes la capacidad de explorar de manera eficiente las propiedades disponibles durante las fechas de su elección, brindándoles la oportunidad de reservar la propiedad que mejor se adapte a sus preferencias y necesidades.",
                link: "https://estol-jr-propiedades-77jj4.ondigitalocean.app/",
                status: "completed",
            },
            Tiznado: {
                logo: logoTiznado,
                description:
                    "Portafolio para un escultor de arte en madera de Chuy, Rocha, Uruguay. Destacando la reutilización y reciclaje de materiales, así como el uso de pigmentación natural, este portafolio exhibe las creaciones esculturales del artista para el mundo.",
                link: "https://tiznado.uy",
                status: "completed",
            },
            MoovieGram: {
                logo: logoMoovieGram,
                description:
                    "MooviGram es un proyecto personal. Es una red social diseñada con el propósito de compartir y comentar sobre películas. Los usuarios tienen la capacidad de compartir sus experiencias cinematográficas junto con opiniones perspicaces, permitiendo que sus seguidores descubran y se involucren con estas recomendaciones. En sus perfiles de usuario, las personas pueden compartir de manera fluida las películas que ya han disfrutado y crear una lista de películas que aspiran ver en el futuro.",
                link: "https://mooviegram.com/",
                status: "developing",
            },
        },
    };

    return (
        <section id="projects" className="container-fluid py-5">
            <h2 className="text-center pt-5">{title[language]}</h2>
            <div className="row mt-3 d-flex align-items-center">
                <div className="col-md-6 border-end border-dark">
                    <div className="py-3">
                        <h3 className="text-center mb-3">{subtitles[language].subtitle1}</h3>
                        <GridComponent status={"completed"} projects={projects} language={language} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="py-3">
                        <h3 className="text-center mb-3">{subtitles[language].subtitle2}</h3>
                        <GridComponent status={"developing"} projects={projects} language={language} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProjectsComponent;
