import React, { Fragment, useCallback, useContext, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

import { LanguageContext } from "../../../Contexts/LanguageContext";
import { axiosConfig } from "../../../Helpers/AxiosRequestHelper";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

const ContactComponent = () => {
   const { language } = useContext(LanguageContext);
   const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
   const [contactForm, setContactForm] = useState({
      name: "",
      email: "",
      message: "",
      token: "",
   });

   const { name, email, message } = contactForm;

   const title = {
      en: "Let's work together!",
      es: "¡Trabajemos juntos!",
   };

   const subtitle = {
      en: "To discuss your project or address any inquiries, feel free to get in touch with me. I am here to collaborate with you and create something exceptional together.",
      es: "Para discutir tu proyecto o responder a cualquier pregunta, no dudes en ponerte en contacto conmigo. Estoy aquí para colaborar contigo y crear algo excepcional juntos.",
   };

   const fields = {
      en: ["Name", "Email", "Message"],
      es: ["Nombre", "Email", "Mensaje"],
   };

   const handleFormSubmit = (e) => {
      e.preventDefault();

      axios(axiosConfig("POST", "/api/contact/new-contact", contactForm, true))
         .then(({ data }) => {
            setContactForm({ name: "", email: "", message: "" });
            return Swal.fire(language === "en" ? "Message sent" : "Mensaje enviado", data.message[language], "success");
         })
         .catch((err) => {
            console.log(err);
            return Swal.fire("Error", err.response.data.message[language], "error");
         });

      setRefreshReCaptcha(!refreshReCaptcha);
   };

   const recaptchaVerify = useCallback((token) => {
      setContactForm({ ...contactForm, token });
   }, []);

   return (
      <section id="contact" className="estol-bg-danger-linear-vertical-top py-5">
         <div className="container py-5">
            <div className="row">
               <div className="col-md-6 order-md-1 order-2">
                  <form className="p-3 border rounded bg-light estol-shadow-light" onSubmit={handleFormSubmit}>
                     {fields[language].map((field, i) => (
                        <Fragment key={i}>
                           <div className="form-group mb-2">
                              <label htmlFor={field.toLowerCase()}>{field}</label>
                              {field !== "Message" && field !== "Mensaje" ? (
                                 <input
                                    type={field === "Name" || field === "Nombre" ? "text" : "email"}
                                    name={field === "Name" || field === "Nombre" ? "name" : "email"}
                                    className="form-control"
                                    value={field === "Name" || field === "Nombre" ? name : email}
                                    onChange={(e) => setContactForm({ ...contactForm, [field === "Name" || field === "Nombre" ? "name" : "email"]: e.target.value })}
                                 />
                              ) : (
                                 <textarea
                                    cols="30"
                                    rows="5"
                                    name="message"
                                    className="form-control"
                                    value={message}
                                    onChange={(e) => setContactForm({ ...contactForm, message: e.target.value })}
                                 ></textarea>
                              )}
                           </div>
                        </Fragment>
                     ))}
                     <GoogleReCaptcha onVerify={recaptchaVerify} refreshReCaptcha={refreshReCaptcha} />
                     <button type="submit" className="btn estol-btn-danger w-100 mt-2">
                        Enviar
                     </button>
                  </form>
               </div>
               <div className="col-md-6 order-md-2 order-1 text-light d-flex">
                  <div className="m-auto w-100">
                     <h2 className="text-center py-3">{title[language]}</h2>
                     <h5 className="text-center">{subtitle[language]}</h5>
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
};

export default ContactComponent;
