import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "animate.css";
import "./Styles/App.css";
import Router from "./Router";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { recaptchaKey } from "./env";

function App() {
   return (
      <GoogleReCaptchaProvider
         reCaptchaKey={recaptchaKey}
      >
         <div className="App d-flex flex-column min-vh-100">
            <Router />
         </div>
      </GoogleReCaptchaProvider>
   );
}

export default App;
