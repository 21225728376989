import React, { useContext } from "react";
import { LanguageContext } from "../../../Contexts/LanguageContext";

const SkillsComponent = () => {
   const { language } = useContext(LanguageContext);

   const title = {
      en: "Skills",
      es: "Habilidades",
   };

   const content = {
      en: {
         design: {
            title: "WEB Design",
            text: "I specialize in developing applications with highly intuitive interfaces, with a focus on optimizing the user experience. My primary goal is to achieve the highest standards of usability, ensuring that each interaction is seamless, and intuitive. Through a meticulous design and development process, I aim not only to satisfy but to exceed user needs and expectations, delivering an exceptionally intuitive and effective experience in every application I develop.",
            skills: ["Photoshop", "Canva", "Corel"],
         },
         backend: {
            title: "Back-End Development",
            text: "I build robust and secure back-end systems that operate efficiently to support application infrastructure. My focus is on developing resilient solutions that not only meet the most stringent security standards but also optimize operational efficiency, ensuring exceptional performance and solid integrity in the backend of the applications I develop.",
            skills: ["Node.js", "Express.js", "MongoDB", "PHP", "MySQL"],
         },
         frontend: {
            title: "Front-End Development",
            text: "I build highly efficient front-ends, focusing on creating exceptional user experiences. My approach is geared towards performance and usability optimization, ensuring that the interfaces I develop are not only visually appealing but also intuitive and effective. I constantly strive to implement best practices and current technologies to ensure the delivery of front-ends that meet and exceed user expectations.",
            skills: ["React.js", "Laravel", "CSS", "JavaScript"],
         },
      },
      es: {
         design: {
            title: "Diseño WEB",
            text: "Me especializo en el desarrollo de aplicaciones con interfaces altamente intuitivas, con un enfoque centrado en la optimización de la experiencia del usuario. Mi objetivo primordial es alcanzar los más elevados estándares de usabilidad, asegurando que cada interacción sea fluida, intuitiva. A través de un meticuloso proceso de diseño y desarrollo, busco no solo satisfacer, sino superar las necesidades y expectativas de los usuarios, ofreciendo así una experiencia excepcionalmente intuitiva y eficaz en cada aplicación que desarrollo.",
            skills: ["Photoshop", "Canva", "Corel"],
         },
         backend: {
            title: "Desarrollo Back-End",
            text: "Construyo sólidos y seguros sistemas back-end que operan de manera eficiente para respaldar la infraestructura de aplicaciones. Mi enfoque se centra en el desarrollo de soluciones robustas que no solo cumplen con los estándares de seguridad más exigentes, sino que también optimizan la eficiencia operativa, garantizando así un rendimiento excepcional y una integridad sólida en el backend de las aplicaciones que desarrollo.",
            skills: ["Node.js", "Express.js", "MongoDB", "PHP", "MySQL"],
         },
         frontend: {
            title: "Desarrollo Front-End",
            text: "Construyo front-ends altamente eficientes, centrándome en la creación de experiencias de usuario excepcionales. Mi enfoque se orienta hacia la optimización del rendimiento y la usabilidad, asegurando que las interfaces que desarrollo no solo sean visualmente atractivas, sino también intuitivas y eficaces. Busco constantemente implementar las mejores prácticas y tecnologías actuales para garantizar la entrega de front-ends que cumplan y superen las expectativas de los usuarios.",
            skills: ["React.js", "Laravel", "CSS", "JavaScript"],
         },
      },
   };

   return (
      <section id="skills" className="py-5 container-fluid estol-bg-danger-linear-vertical text-center text-light">
         <div className="container py-5">
            <h2 className="text-center pb-3 fw-bold">{title[language]}</h2>
            <div className="row g-5">
               {Object.entries(content[language]).map(([key, area]) => (
                  <article key={key} className={`col-lg-4 d-flex flex-fill py-3  ${key === "backend" ? "border-start border-end border-light" : ""}`}>
                     <div className="d-flex flex-column">
                        <h4 className="fw-bold mb-auto">{area.title}</h4>
                        <p className="my-auto py-3">{area.text}</p>
                        <ul className="list-group list-group-flush rounded w-75 mx-auto">
                           {area.skills.map((s, i) => (
                              <li className={`list-group-item ${s === "Node.js" || s === "Express.js" || s === "MongoDB" || s === "React.js" ? 'estol-text-danger' : ""}`} key={s}>
                                 {s}
                              </li>
                           ))}
                        </ul>
                     </div>
                  </article>
               ))}
            </div>
         </div>
      </section>
   );
};

export default SkillsComponent;
