import React, { Fragment } from "react";

const GridComponent = ({ status, projects, language }) => {
   const buttonText = {
      en: "Visit this site",
      es: "Visitar este sitio",
   };

   return (
      <div className="row g-3 d-flex flex-fill justify-content-center my-3">
         {Object.entries(projects[language]).map(([key, project]) => (
            <Fragment key={key}>
               {project.status === status && (
                  <article className="col-xl-6 col-10">
                     <div className="text-center border bg-light rounded-top estol-shadow h-100 d-flex flex-column">
                        <div className="mx-auto d-flex p-3">
                           <img src={project.logo} alt={key.replaceAll("-", " ")} className="mx-auto img-thumbnail" />
                        </div>
                        <h3 className="estol-text-danger mt-auto">{key.replaceAll("-", "")}</h3>
                        <p className="mb-auto mx-3 pb-3">{project.description}</p>
                        {status === "completed" && (
                           <a
                              href={project.link || "/#"}
                              target="_black"
                              referrerPolicy="noreferrer"
                              className={`btn estol-btn-danger w-100 rounded-0 rounded-bottom ${key === "Estol" ? "disabled" : ""}`}
                           >
                              {buttonText[language]}
                           </a>
                        )}
                     </div>
                  </article>
               )}
            </Fragment>
         ))}
      </div>
   );
};

export default GridComponent;
