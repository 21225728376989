import React from 'react'

const MainComponent = ({children}) => {
  return (
    <main id='home'>
      {children}
    </main>
  )
}

export default MainComponent