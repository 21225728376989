import React, { useContext } from "react";

import MainComponent from "../Layouts/Main/MainComponent";
import AboutMeComponent from "./HomeComponents/AboutMeComponent";
import { LanguageContext } from "../../Contexts/LanguageContext";
import SkillsComponent from "./HomeComponents/SkillsComponent";
import ProjectsComponent from "./HomeComponents/ProjectsComponent";
import ServicesComponent from "./HomeComponents/ServicesComponent";
import ContactComponent from "./HomeComponents/ContactComponent";

import react from "../../Images/react.png";
import node from "../../Images/node.png";
import mongo from "../../Images/mongo.png";

const HomeComponent = () => {
   const { language } = useContext(LanguageContext);

   const h1 = {
      es: (
         <h1 className="text-center py-5">
            ¡Hola! Me llamo <span className="estol-text-danger">Esteban Olivera</span>.<br />
            Soy diseñador WEB y desarrollador Full-Stack junior
         </h1>
      ),
      en: (
         <h1 className="text-center py-5">
            Hello! I'm <span className="estol-text-danger">Esteban Olivera</span>.<br /> I'm a junior WEB designer and Full-Stack Developer
         </h1>
      ),
   };

   return (
      <MainComponent>
         <AboutMeComponent />
         <div id="javascript-images" className="d-flex w-100">
            <div className="w-25 m-auto text-center">
               <img src={react} alt="reactjs" className="w-100" />
            </div>
            <div className="w-25 m-auto text-center">
               <img src={node} alt="nodejs" className="w-100" />
            </div>
            <div className="w-25 m-auto text-center">
               <img src={mongo} alt="mongodb" className="w-100" />
            </div>
         </div>
         <SkillsComponent />
         <ProjectsComponent />
         <ServicesComponent />
         <ContactComponent />
      </MainComponent>
   );
};

export default HomeComponent;
