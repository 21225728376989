// LanguageContext.js

import React, { createContext, useState } from "react";

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
   const [language, setLanguage] = useState("en");

   const toggleLanguage = () => {
      setLanguage((prevLanguage) => (prevLanguage === "en" ? "es" : "en"));
   };

   return <LanguageContext.Provider value={{ language, toggleLanguage }}>{children}</LanguageContext.Provider>;
};

export { LanguageProvider, LanguageContext };
