import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HeaderComponent from "./Components/Layouts/Header/HeaderComponent";
import HomeComponent from "./Components/Home/HomeComponent";
import FooterComponent from "./Components/Layouts/Footer/FooterComponent";

const Router = () => {
   return (
      <BrowserRouter>
         <HeaderComponent />
         <Routes>
            <Route exact path="/" element={<HomeComponent />} />
         </Routes>
         <FooterComponent />
      </BrowserRouter>
   );
};

export default Router;
