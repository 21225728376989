import React, { useContext } from "react";
import { LanguageContext } from "../../../Contexts/LanguageContext";

import avatar from "../../../Images/face.png"

const AboutMeComponent = () => {
   const { language } = useContext(LanguageContext);

   const h1 = {
      es: (
         <h1 className="text-center pb-5">
            ¡Hola! Me llamo <span className="estol-text-danger">Esteban Olivera</span>.<br />
            Soy diseñador WEB y desarrollador Full-Stack junior
         </h1>
      ),
      en: (
         <h1 className="text-center pb-5">
            Hello! I'm <span className="estol-text-danger">Esteban Olivera</span>.<br /> I'm a junior WEB designer and Full-Stack Developer
         </h1>
      ),
   };

   const en = [
      "As a technology enthusiast since my adolescence, I have developed a strong self-taught understanding of hardware, software, and operating systems. My venture into FrontEnd development began with an active search for online resources, driven by the desire to undertake diverse projects.",
      "Despite not completing my formal studies within the expected timeframe, I have compensated for this shortfall with unwavering commitment and steadfast loyalty throughout my 14 years of professional experience, serving in two companies that, although not within the technological sector, have provided valuable lessons and transferable skills.",
      "In early 2020, a colleague from the technology sector recommended Hack Academy as an opportunity to solidify my FrontEnd knowledge. During my participation in this program, I also remotely attended live videoconferencing courses on PHP, Laravel, MySQL, Node.js, Express, and MongoDB. This learning period included the development and work with APIs, marking a significant expansion in my skill set.",
      "To reinforce these skills, I undertook an online course on React.js, a technology I used to create my personal portfolio and a web application for a real estate company. Currently, my focus revolves around NodeJS, MongoDB, and ReactJS—technologies that I consider fundamental and cutting-edge in today's web development landscape.",
      "At this moment, I am actively seeking my first job opportunity as an employee in the technology sector, eager to apply my skills and contribute to the success of an innovative team.",
   ];

   const es = [
      "Como apasionado de la tecnología desde mi adolescencia, he desarrollado un sólido conocimiento autodidacta en hardware, software y sistemas operativos. Mi incursión en el desarrollo FrontEnd comenzó con una búsqueda activa de recursos en línea, impulsada por el deseo de llevar a cabo diversos proyectos.",
      "A pesar de no haber completado mis estudios formales en el tiempo esperado, he compensado esa carencia con un compromiso inquebrantable y una lealtad constante en los 14 años de experiencia laboral, sirviendo en dos empresas que, aunque no pertenecen al sector tecnológico, me han brindado valiosas lecciones y habilidades transferibles.",
      "En el inicio de 2020, un colega del sector tecnológico me recomendó Hack Academy como una oportunidad para consolidar mis conocimientos en FrontEnd. Durante mi participación en este programa, también cursé de manera remota y en modalidad de videoconferencia en vivo los cursos de PHP, Laravel, MySQL y Node.js, Express y MongoDB. Este periodo de aprendizaje incluyó el desarrollo y trabajo con APIs, marcando una expansión significativa en mi conjunto de habilidades.",
      "Para reforzar estos conocimientos, emprendí un curso en línea sobre React.js, tecnología que utilicé para la creación de mi portafolio personal y una aplicación web para una empresa inmobiliaria. En la actualidad, mi enfoque se centra en NodeJS, MongoDB y ReactJS, tecnologías que considero fundamentales y de vanguardia en el panorama actual del desarrollo web.",
      "En este momento, estoy en la búsqueda de mi primera oportunidad laboral como empleado en el sector tecnológico, ansioso por aplicar mis habilidades y contribuir al éxito de un equipo innovador.",
   ];

   const about = language === "en" ? en : es;

   return (
      <section id="about-me" className="text-center py-5 container">
         <img src={avatar} alt="Esteban's avatar" className="mb-5 avatar"  />
         {h1[language]}
         {about.map((p, i) => (
            <p key={i} className={i === about.length - 1 ? "fw-bold" : ""}>
               {p}
            </p>
         ))}
      </section>
   );
};

export default AboutMeComponent;
